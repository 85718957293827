import { useMatomo } from '@/composables/useMatomo';
import { useDeviceStore } from '@/stores/device';
import { useHistoryStore } from '@/stores/history';
import { useUserStore } from '@/stores/user';
import { asInt } from '@/utils';
import { createRouter, createWebHistory,  type RouteLocationNormalized } from 'vue-router';

function title( forRoute: RouteLocationNormalized ):string {
    // if (forRoute.params){
    //     return Object.values(forRoute.params).flat().join(" ");
    // }

    // if (forRoute.name){
    //     return forRoute.name.toString();
    // }

    return "Thuis.nl - 1 op 1 met echte Hollandse vrouwen"
}

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: () => import('../views/home.vue'),
            name: 'home'
        },
        {
            path: '/styles',
            component: () => import('../views/styles.vue'),
            name: 'styles'
        },
        {
            path: '/performer/:current/:service/',
            component: () => import('../views/profiles.vue'),
            name: 'performer',
            props: route => ({
                current: asInt(route.params.current),
                service: route.params.service
            })
        },
        {
            path: '/login',
            component: () => import('../views/login.vue'),
            name: 'login'
        },
        {
            path: '/reset-password/:userId/:tolkien',
            component: () => import('../views/resetPassword.vue'),
            name: 'reset-password',
            props: route => ({
                userId: parseInt( route.params.userId.toString() ),
                tolkien: route.params.tolkien
            })
        },
        {
            path: '/install',
            component: () => import('../views/install.vue'),
            name: 'install'
        },
        {
            path: '/search',
            component: () => import('../views/search.vue'),
            name: 'search'
        },
        {
            path: '/search/:query',
            component: ()=> import('../views/home.vue'),
            name: 'search-results',
            props: route => ({
                slice: 'search',
                query: route.params.query.toString()
            })
        },
        {
            path: '/category/:slug',
            component: ()=> import('../views/home.vue'),
            name: 'category',
            props: route => ({
                slice: 'category',
                slug: route.params.slug
            })
        },
        {
            path: '/favorites',
            component: () => import('../views/home.vue'),
            name: 'favorites',
            props: () => ({
                slice: 'favorites'
            })
        },
        {
            path: '/chats',
            component: () => import('../views/home.vue'),
            name: 'chats',
            props: () => ({
                slice: 'chats'
            })
        },
        {
            path: '/account',
            component: () => import('../views/account.vue'),
            name: 'account'
        },
        {
            path: '/gyros',
            component: () => import('../views/gyros.vue'),
            name: 'gyros'
        },
        {
            path: '/size',
            component: () => import('../views/size.vue'),
            name: 'size'
        },
        {
            path: '/flipper',
            component: () => import('../views/flipper.vue'),
            name: 'flipper'
        },
        {
            path: "/rotate",
            component: () => import('../views/rotate.vue'),
            name: "rotate"
        }

    ]
});

router.beforeEach(async (to, from, next) => {
    //catch 404s and just redirect them home
    if (!to.matched.length){
        next( { name: "home" } );
        return;
    }

    // redirect to installation page if this is no PWA
    // alllow visiting the reset-password page in a browser &shrug;

    const installationRequired = !(useDeviceStore().isPWA || ['install', 'reset-password'].includes( to.name!.toString() ));
    if (installationRequired){
        next( {name: "install"} );
        return;
    }

    //users using the pwa shouldn't see the installation page again
    if (useDeviceStore().isPWA && to.name!.toString() == 'install'){
        next( { name: "home" } );
        return;
    }

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages: any[] = ['login', 'install', 'reset-password', 'layout'];
    const authRequired = !publicPages.includes(to.name && to.name.toString());

    if (!authRequired){
        document.title = title( to );
        next();
        return;
    }

    const user = useUserStore();
    
    await user.authentication();

    if (!user.isLoggedIn()){
        next({ name: "login"});
    } else {
        document.title = title( to );
        next();
    }

});

router.afterEach( ( to )=> {
    useHistoryStore().add( to.name as string, to.fullPath ) 
    useMatomo().trackPageView();
} )

export default router;