import type { Performer, Status as PerformerStatus, Service, ServiceStatus, SimplePerformer, Status } from '@/ontology/performer';
import { defineStore } from 'pinia';
import notifications from '@/socket';
import same from 'deep-equal';
import config from '@/config';
import { serviceStatus, statuses, type Filter, defaultServices, lastSeenAgo } from '@/api/performer/utils';
import { getListPerformers, getByAdvert, getPeekers, getTeasers, getById, getRecommended, listUsernames, getByIds, getCategoryListPerformers } from '@/api/performer';
import { addFavorite, getFavorites, removeFavorite } from '@/api/client/client_accounts.favorites';
import type { Paged, Response } from '@/api/response';
import { useUserStore } from './user';
import { useAlertsStore } from './alerts';
import { minutes } from '@/utils';
import i18n from '@/translations';
import { getUnixTime } from 'date-fns';
import { addSubscriptions, removeSubscriptions } from '@/api/client/client_accounts.subscriptions';
import { getThreads } from '@/api/messaging';
import type { EmailNotification } from '@/ontology/notifications';
import { useCategoriesStore } from './categories';

export type SliceName = "home" | "favorites" | "search" | "chats" | "category" | "voyeur" | undefined

interface State {
    performers: { [id: number]: Performer };
    performerNames: SimplePerformer[] | undefined;
    slices: { [name: string]: Slice };
    sliceInfiltrants: number[],
    currentSlice: SliceName;
    //advert number of the currently selected performer
    selected: number | undefined;
    previous: Performer | undefined;
    next: Performer | undefined;
    withNewMessage: number[];
}

// a slice performers is a specific list of performers.
// It is a filtered subset of performers, sorted in a specific way
// In thuis.nl for example, there's the list for the grid, for related performers
// to the current performer, performers eligable for teasing or meekijking
export interface Slice {
    name: string;
    filter: Filter;
    items: number[];
    total?: number;
    //timestamp indicating the age of this slice
    fetchedAt: number;
    status: 'new' | 'fetching' | 'fetched' | 'error';
}

interface ServiceUpdate {
    performerId: number;
    serviceName: Service;
    serviceStatus: boolean;
    status?: PerformerStatus;
    countries?: string;
    services?: { [key: string]: boolean };
}

type Loader = (filter: Filter) => Promise<Response<Paged<Performer>>>;

export const usePerformerStore = defineStore('performer', {
    state: (): State => ({
        performers: {},
        performerNames: undefined,
        slices: defaultSlices(["home", "favorites", "search", "chats", "category"]),
        currentSlice: 'home',
        sliceInfiltrants: [],
        selected: undefined,
        previous: undefined,
        next: undefined,
        withNewMessage: []
    }),
    actions: {
        initialize() {
            notifications.subscribe('status', (update: ServiceUpdate) => {
                this.updateServices(update);
            });

            notifications.subscribe('service', (update: ServiceUpdate) => {
                this.updateServices(update);
            });
            notifications.subscribe('authentication', (update: { type: 'loggedin' | 'loggedout' }) => {
                switch (update.type) {
                    case 'loggedin':
                        this.onlogin();
                        break;
                    case 'loggedout':
                        this.onlogout();
                        break;
                    default:
                        throw new Error(`That's a surprising authentication type: ${update.type}`);
                }
            });
            notifications.subscribe('email', this.newEmail);
        },

        updateServices(update: ServiceUpdate) {
            let performer = this.performers[update.performerId];
            if (!performer){

                if( this.shouldLoad( update ) ) {
                    //if the update is about a performer that becomes available
                    //for the 'current' slice and not known yet, load her!
                    //initialize with a very unavailable status and services to prevent 
                    //loading performers often, because of multiple status updates in a row
                    this.performers[update.performerId] = {
                        id: update.performerId,
                        status: "OFFLINE",
                        services: defaultServices()
                    } as Performer;
                    this.loadPerformerById(update.performerId);
                }
                return;
            }

            const oldStatus = performer.status;

            if (update.status) {
                performer.status = update.status;
            }

            if (update.services) {
                for (let service in update.services) {
                    //TODO: handle VIPCAM too
                    if (performer.services[service]){
                        performer.services[service].intention = update.services[service];
                    }
                }
            }

            if (update.serviceName) {
                performer.services[update.serviceName].intention = update.serviceStatus;
            }

            //redo all service statuses for this performer..
            const old = statuses(performer.services);
            for (let service in performer.services) {
                performer.services[service].status = serviceStatus(performer.status, service as Service, performer.services[service].intention);
            }
            const current = statuses(performer.services);

            if (becameAvailable(current.cam, old.cam)){
                notifications.sendLocalEvent(
                    "performer_available", 
                    { performer: performer.id }
                )

                if (this.isFavorite( performer.id ) && (lastSeenAgo(performer) > minutes(60))){
                    performer.statusUpdated = getUnixTime(new Date());
                    useAlertsStore().performerMessage({advert: performer.advertNumber, message: i18n.global.t('account.alerts.favoriteOnline', {performer: performer.nickname}), avatar: performer})
                }
            }

            if (wentOffline( performer.status, oldStatus ) ){
                notifications.sendLocalEvent(
                    "performer_offline",
                    { performer: performer.id }
                )
            }

            //now check if a performer maybe became available for some slices\
            this.updateSlices(performer.id, old, current);
        },
        // an update comes in about an unknown performer so far.
        // Did she become available for the 'current' slice?
        shouldLoad( update:ServiceUpdate ){
            //TODO: implement properly
            return true;
            // const hotServices:any[] = [
            //     'peek', 'voyeur'
            // ]

            // // if ( !hotServices.includes( this.currentSlice ) ){
            // //     return false;
            // // }

            // if (update.services !== undefined){
            //     return update.services![this.currentSlice!]
            // }

            // if (update.serviceName){
            //     return (update.serviceName == this.currentSlice) && update.serviceStatus;
            // }
            // return false;
        },
        async loadPerformer(advertNumber: number) {
            const { error, result } = await getByAdvert(advertNumber);
            if (error) {
                return { error }
            }

            if (!result) {
                throw new Error('Imposible!');
            }

            this.setPerformer(result);

            return { result };
        },
        async listUsernames() {
            const { error, result } = await listUsernames();
            if (error) {
                return;
            }

            if (!result) {
                throw new Error('Imposible!');
            }

            this.performerNames = result;
        },

        async setPerformer(value: Performer) {
            const known = this.performers[value.id];

            if (known){
                if (known.lastShownPhoto){
                    value.lastShownPhoto = known.lastShownPhoto;
                }

                //copy the photos from known if they are present in the cache, but not in the 
                //freshly loaded version.
                if (!value.photos && known.photos){
                    value.photos = known.photos;
                }

                this.performers[known.id]= value;

                this.updateSlices( value.id, statuses(known.services), statuses( value.services) );
            } else {
                this.performers[value.id] = value;
            }
            
        },
        async loadPerformerById(id: number) {
            const { error, result } = await getById(id);
            if (error) {
                //TODO: handle that error
            }
            if (!result) {
                throw new Error('Impossible');
            }

            this.setPerformer(result);

            return result;
        },
        async selectPerformer(advertNumber: number | undefined) {
            this.selected = advertNumber
        },
        convertAdvertToId(advert:number): number | undefined{
            const found = Object.values( this.performers )
                .find( ({advertNumber})=> advertNumber == advert );
            return found ? found.id : undefined;
        },

        nameByAdvert(advert:number){
            const id = this.convertAdvertToId(advert);
            if (!id){
                return `fallback advert ${advert}`;
            }
            return this.nameById(id);
        },

        nameById(id:number){
            const p = this.getById(id);
            if (!p){
                return `fallback id ${id}`;
            }
            return p.nickname;
        },

        me(){
            const userStore = useUserStore();
            if (!userStore.account) {
                //TODO: handle this bloody error:
                throw new Error('No accounts have no favorites..');
            }

            return userStore.account.id!;
        },

        async loadSlice(name: string, filter: Filter, loader?: Loader ) {
            const slice = this.getSlice(name);

            if (same(filter, slice.filter) && age(slice.fetchedAt) < config.GridCache) {
                return slice;
            }

            if (!loader){
                switch(name){
                    case 'peek':
                        loader = getPeekers;
                        break;
                    case 'teaser':
                        loader = getTeasers;
                        break;
                    case 'favorites':
                        loader = (query: Filter) => getFavorites(this.me(), query );
                        break;
                    case 'chats':
                        loader = (query:Filter) => this.getChatters( query);
                        break;
                    case "category":
                        loader = (query:Filter) => {
                            useCategoriesStore().selected = query.category;
                            return getCategoryListPerformers(query);
                        }
                        break;
                    case 'recommended':
                        //TODO: do we NEED to handle this??
                        throw 'Recommmend should at this moment be done with the loadFavorites and loadRecommended functions';
                    default: 
                        loader = getListPerformers;
                }
            }

            slice.status = 'fetching';
            slice.filter = filter;
            const { error, result } = await loader(filter);

            if (error) {
                //TODO: handle this error. Should not happen off course
                slice.status = 'error';
                return slice;
            }

            if (!result) throw new Error('Impossible');

            slice.status = 'fetched';
            slice.fetchedAt = Date.now();
            slice.total = result.total;
            //add all fetched performers to this.performers
            result.items.forEach(p => this.setPerformer(p));
            
            const newList = result.items.map(p => p.id);
            //make sure the selected performer always remains in the list so 
            //there won't be any errors returning to the grid 
            if (this.selected){
                const selectedId = this.convertAdvertToId( this.selected );
                if ( selectedId && !newList.includes( selectedId ) ){
                    const oldIndex = slice.items.indexOf( selectedId );
                    if (oldIndex > -1){
                        newList.splice( oldIndex, 0, selectedId );
                    }
                }
            }

            slice.items = newList;
            return slice;
        },

        async loadFavorites(filter: Filter = { offset: 0, limit: 42 }) {
            const userStore = useUserStore();
            if (!userStore.account) {
                //TODO: handle this bloody error:
                throw new Error('No accounts have no favorites..');
            }
            
            const id = userStore.account.id!;
            return await this.loadSlice('favorites', filter, (query: Filter) => {
                return getFavorites(id, query);
            });
        },

        async getChatters( query:Filter ){
            return new Promise<Response<Paged<Performer>>>( async(resolve )=>{
                //laad de laatste chats..
                const { error: threadError, result:threadResult } = await getThreads(query.offset, query.limit, true);
                if (threadError){
                    resolve( { error: threadError } );
                    return;
                }

                if (!threadResult){
                    resolve( { error: { code: 500, message: "impossible, no result loading threads" } });
                    return;
                }

                const performers = threadResult.items.map( conversation => conversation.performerId );

                this.withNewMessage = threadResult.items.filter( t => t.readStatus == 'NEW' ).map( t => t.performerId );

                //laad de bijbehorende performers (die we nog niet hebben)
                const { error, result } = await getByIds( query, performers );

                if (error){
                    resolve( { error } );
                    return;
                }

                if (!result){
                    resolve( { error: { code: 500, message: "impossible result: no performers while no error in getChatters" } });
                    return;
                }

                const items = performers.map( id => result.items.find( p => p.id == id )).filter( p => p != undefined) as Performer[]

                resolve( {
                    result: {
                        offset: result.offset, 
                        total: result.total, 
                        items
                    }
                })

            })
            
        },

        noMoreNewMessages( forPerformerId: number ){
            const ix =  this.withNewMessage.indexOf( forPerformerId );
            if (ix > -1){
                this.withNewMessage.splice(ix, 1);
            }
        },

        newEmail( notification:EmailNotification ){
            const ix =  this.withNewMessage.indexOf( notification.performerId );
            if (ix == -1){
                this.withNewMessage.push( notification.performerId );
            }
        },

        async loadRecommended(filter: Filter = { offset: 0, limit: 42 }, relatedTo: number) {
            return await this.loadSlice( "recommended", filter, (query:Filter)=>getRecommended(query, relatedTo))
        },

        async loadPeekers(filter: Filter = { offset: 0, limit: 100 }, relatedTo?: number) {
            return await this.loadSlice('peek', filter, (query: Filter) => getPeekers(query, relatedTo));
        },

        async loadTeasers(filter: Filter = { offset: 0, limit: 100 }, relatedTo?: number) {
            return await this.loadSlice('voyeur', filter, (query: Filter) => getTeasers(query, relatedTo));
        },

        async toggleFavorite(id: number) {
            const account = useUserStore().account;
            if (!account) {
                //TODO: handle this bloody error:
                throw new Error('No accounts have no favorites..');
            }

            const favorites = this.getSlice('favorites');
            const performer = this.getById( id );
            if (!performer.isFavorite) {
                const { error, result } = await addFavorite(id, account.id!);
                if (result && !error) {
                    performer.isFavorite = true;
                    if (!favorites.items.includes( id )){
                        favorites.items.unshift(id);
                    }
                }
            } else {
                //remove the favorite
                const { error, result } = await removeFavorite(id, account.id!);
                if (result && !error) {
                    performer.isFavorite = false;
                }
            }
        },

        //TODO: seems something is not quite right in this context
        syncFavorites(){
            const favorites = this.getSlice('favorites').items;
            for(let k = favorites.length-1; k>-1; k--){
                const performer = this.getById(favorites[k]);
                if (!performer.isFavorite){
                    favorites.splice( k, 1 );
                }
            }
        },

        getSlice(name: string) {
            if (!(name in this.slices)) {
                throw new Error(`Unknown slice ${name}. Available: ${Object.keys(this.performers).join(',')} `);
            }
            return this.slices[name];
        },

        //adds the advert number 'which' before the advert number 'current' in the current Slice
        //the slice contains id though, so a translation is needed
        addBefore(which: number, current:number){
            if (!this.currentSlice){
                throw "performerStore.addBefore: You're not even on a slice";
            }

            const whichAsId = this.convertAdvertToId( which );
            if (!whichAsId){
                throw `${which} to be added isn't even a known performer`;
            }

            const currentAsId = this.convertAdvertToId( current );
            if (!currentAsId){
                throw `the current ${current} isn't even a known performer`;
            }

            const whichIndex = this.slices[this.currentSlice].items.findIndex( id => whichAsId == id );
            if (whichIndex > -1){
                //remove from the slice to move it up...
                this.slices[this.currentSlice].items.splice(whichIndex, 1);
            } else {
                this.sliceInfiltrants.push( whichAsId );
            }

            const index = this.slices[this.currentSlice].items.findIndex( id => currentAsId == id );
            if (index == -1){
                throw `the current ${currentAsId} isn't even in the slice`
            }

            this.slices[this.currentSlice].items.splice(index, 0, whichAsId);
            //this.slices[this.currentSlice].items[index-1] = whichAsId;
        },


        //there are slices that are filtered on the availability of a service.
        updateSlices(performer: number, old: { [service: string]: ServiceStatus }, current: { [service: string]: ServiceStatus }) {
            []
            .forEach((service) => {
                const slice = this.getSlice(service);
                if (slice.status != 'fetched'){
                    return;
                }

                //if the service status hasn't changed, whether the performer is
                //part of the  slice does not change
                if (old[service] == current[service]) {
                    return;
                }

                switch ('available') {
                    //the service was available, not anymore..
                    case old[service]: {
                        const ix = slice.items.indexOf(performer);
                        //TODO: if she has a session with the current user, don't remove her!
                        if (ix > -1) {
                            slice.items.splice(ix, 1);
                        }
                        break;
                    }
                    //the service became available.
                    //Check if she's a favorite and if so, place her next :-)
                    case current[service]: {
                        slice.items.push(performer);
                        break;
                    }
                }
            });
        },
        resetFetched(name: string) {
            const slice = this.getSlice(name);
            slice.fetchedAt = 0;
        },
        getById(id: number) {
            return this.performers[id];
        },
        isFavorite(id: number) {
            return this.slices.favorites.items.indexOf(id) > -1;
        },
        async toggleSubscribed(performer:Performer) {
            const oldValue = performer.isSubscribed;
            performer.isSubscribed = !performer.isSubscribed;
            try{
                if (performer.isSubscribed){
                    await addSubscriptions( useUserStore().account.id!, performer.id )
                } else {
                    await removeSubscriptions( useUserStore().account.id!, performer.id );
                }
            } catch {
                performer.isSubscribed = oldValue;
            }

        },
        firstAdvert() {
            const firstId = this.slices[ this.currentSlice! ].items[0];
            if (!firstId){
                return undefined;
            }

            return this.performers[ firstId ].advertNumber;
        },
        onlogin() {
            this.loadFavorites();
        },
        onlogout() {
            this.slices.favorites = {
                name: 'favorites',
                filter: { offset: 0, limit: -1 },
                status: 'new',
                items: [],
                fetchedAt: Date.now()
            };
        }
    },

    getters: {
        isFavoriteNotWorkingButWhy(state) {
            const hashed = state.slices.favorites.items.reduce(numberHash, {});
            return (id: number) => {
                return id in hashed;
            };
        }
    }
});

//returns how long ago a moment (in js timestamp) was, in seconds
function age(moment: number): number {
    return (Date.now() - moment) / 1000;
}

function defaultSlices(names: string[]) {
    let result: { [name: string]: Slice } = {};
    for (let name of names) {
        result[name] = {
            name,
            filter: { offset: 0, limit: -1 },
            status: 'new',
            items: [],
            fetchedAt: Date.now()
        };
    }
    return result;
}

function numberHash(sofar: { [key: number]: boolean }, current: number) {
    if (!sofar) {
        sofar = {};
    }

    sofar[current] = true;
    return sofar;
}

function becameAvailable(now:ServiceStatus, old:ServiceStatus){
    return now == "available" && now != old;
}

function wentOffline( now: Status, old: Status ){
    if ( now != "OFFLINE"){
        return false;
    }

    return now != old;
}
