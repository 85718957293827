import notifications from '@/socket';
import { defineStore } from 'pinia';
import type { Message } from '@/ontology/messaging';
import { sendMessage, payMessage, deleteEmail, getMessagesAfter, getConversation } from '@/api/messaging';
import type { Paged } from '@/api/response';
import { asInt, match, sleep } from '@/utils';
import { useUserStore } from './user';
import { append, prepend } from '@/api/messaging/utils';
import i18n from '@/translations';
import { usePerformerStore } from './performer';
import { useLocalizationStore } from './localization';
import type { EmailNotification } from '@/ontology/notifications';

type Status = 'idle' | 'loading_messages' | 'sending_message' | 'loading_more';

function systemMessage(content: string, subject:string = ""):Message{
    return {
        date: new Date(),
        billingStatus: "FREE",
        id: Date.now(),
        folder: 'INBOX',
        performerId: -1,
        content,
        sentBy: "SYSTEM",
        subject,
        readStatus: "OLD",
        type: "email"
    }
}
interface State {
    performerId: number;
    messages?: Paged<Message>;
    status: Status;
    statusResolvers: ((status: Status) => void)[];
}


export const useConversationStore = defineStore('Conversation', {
    state: (): State => ({
        messages: undefined,
        status: 'idle',
        statusResolvers: [],
        performerId: -1
    }),
    actions: {
        initialize() {
            notifications.subscribe('authentication', this.handleNotification);
            notifications.subscribe('email', this.handleNotification);
        },
        //TODO: is this strong-typable?
        handleNotification(update: any) {
            const rules = [
                {
                    when: { type: 'loggedout' },
                    do: () => (this.messages = undefined)
                }, 
                {
                    when: { type: 'new' },
                    do: ()=>this.newMessage(update)
                }
            ];
            const toMatch = update;

            const rule = rules.find(check => match(toMatch, check.when));
            if (rule && rule.do) {
                rule.do();
            } else {
                // console.log('unhandleable now for message:');
                // console.dir(toMatch);
            }
        },

        me(){
            const userStore = useUserStore();
            if (!userStore.account) {
                //TODO: handle this bloody error:
                throw new Error('No accounts have no conversations..');
            }

            return userStore.account.id!;
        },

        async loadMessages(performerId: number) {
            this.performerId = performerId;
            this.messages = undefined;

            if (this.status != 'idle') {
                while ((await this.nextStatus()) != 'idle') {}
            }
            

            this.setStatus('loading_messages' );
            const { error, result } = await getConversation( performerId, 0, 20 );
            if (error) {
                //TODO: most unlikely: a thread that doesn't load properly
                this.status = 'idle';
                return;
            }

            if (!result){
                throw "impossible no ( result and error ) for loading messages";
            }

            //let's see if there's a 'not paid' message in the result. If so, add a system message
            //telling what reading a message costs

            this.messages = this.withPriceMessages( result );

            this.setStatus('idle');
        },

        async loadMore(){
            if (this.status != 'idle'){
                return;
            }
            if (!this.performerId) throw 'loadMessages before loading more buster!';
            if (!this.messages) throw 'No messages to append to';

            //count only the messages from client or performert, the others aren't persisted by the servert
            const offset = this.messages.items.filter( ({sentBy}) => ['CLIENT', 'PERFORMER'].includes(sentBy) ).length;

            if (offset >= this.messages.total){
                //nothing to fetch no more..
                return;
            }

            this.setStatus( 'loading_more' )

            const { error, result } = await getConversation( this.performerId, offset, 20 );

            if (error){
                throw error;
            }

            if (!result){
                throw "impossible";
            }

            this.messages = append( this.withPriceMessages( result ), this.messages );
            this.setStatus( 'idle' );
        },

        withPriceMessages( source: Paged<Message> ): Paged<Message>{
            const result = {
                offset: source.offset, total: source.total, items: source.items.slice()
            }
            const  cost =  asInt( useLocalizationStore().credits_per_email || "150" );

            for( let k=result.items.length-1; k >= 0; k--){
                const message = result.items[k];

                if (message.billingStatus != "NOT PAID"){
                    continue;
                }


                const toAdd = systemMessage( i18n.global.t("profile.session.email.price", { cost } ), "price" );
                result.items.splice(k+1, 0, toAdd)
            }

            return result;
        },

        resetMessages(forPerformerId:number){
            //only reset the messages if the current performer requests so
            if (this.performerId != forPerformerId){
                return;
            }
            this.performerId = -1;
            this.messages = undefined;
        },

        setStatus(value: Status) {
            if (value == this.status) {
                return;
            }

            this.status = value;
            this.statusResolvers.forEach(f => f(value));
            this.statusResolvers = [];
        },

        //await this if you need to wait for another state.
        async nextStatus() {
            return new Promise<Status>(resolve => {
                this.statusResolvers.push(resolve);
                //TODO: how about a rejection? Maybe.. a global timeout for the status?
            });
        },

        async newMessage( notification:EmailNotification ){
            if (notification.performerId == this.performerId){
                //add the message to this conversation...
                if (this.status != 'idle') {
                    while ((await this.nextStatus()) != 'idle') {}
                }

                if (!this.messages){
                    //not possible!
                    throw `There should be messages for ${this.performerId} by now`;
                }

                const { error, result: newMessages } = await getMessagesAfter( notification.replyId );
                if (error){
                    throw "Error loading additional messages";
                }

                const ids = this.messages.items.map( ({id}) => id )

                newMessages.items = newMessages.items.filter( ({id}) => !ids.includes(id) );

                this.messages = prepend( this.withPriceMessages( newMessages ), this.messages! );

                notifications.sendLocalEvent("new_message_in_conversation", { performer: this.performerId });
            }
        },

        async deleteEmail(messageId: number, index: number) {
            if(!this.messages){
                return;
            }
            const { error, result } = await deleteEmail(messageId);

            if (error) {
                return error;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            this.messages!.items.splice(index, 1);
        },

        markLastMessageAsOld(){
            if (!this.messages){
                return;
            }
            if (!this.messages.items.length){
                return;
            }

            if (!(this.messages.items[0].readStatus == 'NEW')){
                return;
            }

            this.messages.items[0].readStatus = 'OLD';
        },

        selfTyping(){
            //console.log("ga zelf typen yo");
            //TODO: typen doorsturen aan performer / reageren op typen??
            //
            // if ( ( Date.now() - this.selfTyped ) < seconds(2) ){
            //     return;
            // }

            // notifications.sendCustomEvent('event', {
            //     event: 'typing_received',
            //     receiverId: this.performerId,
            //     receiverType: 'ROLE_PERFORMER',
            //     content: encodeURIComponent(JSON.stringify({
            //         recentTyping:true, 
            //         inBuffer:true, 
            //         senderType: "ROLE_CLIENT",
            //         senderId: useUserStore().account!.id
            //     }))
            // });
            // this.selfTyped = Date.now();
        },

        async send(content: string, toPerformer: number) {
            if (!this.messages) {
                throw new Error('impossible');
            }

            const repliedFrom = this.messages.items.length ? this.messages.items[0].id : undefined;

            const performer = usePerformerStore().getById( toPerformer );
            if (!performer){
                throw new Error("make sure the performer account is loaded before sending a message to her");
            }

            const subject = this.messages.items.length ? this.messages.items[0].subject : i18n.global.t('profile.session.email.subject', { performer: performer.nickname } );

            const message: Partial<Message> = {
                performerId: toPerformer,
                content,
                type: 'email',
                repliedFrom,
                subject
            };

            const { error, result } = await sendMessage(message);

            if (error) {
                return error;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            this.messages.items.unshift(result);
            this.messages.total+=1;
            notifications.sendLocalEvent("new_message_in_conversation", { performer: toPerformer });

            
            if (!['AVAILABLE', 'OFFLINE'].includes( performer.status )){
                await sleep(500);
                this.messages.items.unshift( systemMessage( i18n.global.t("profile.session.email.shes-busy", { performer: performer.nickname } ) ));
            }
        },

        async buyMessage(messageId: number) {
            const clientId = useUserStore().account.id!;
            if (!this.messages) {
                //should not be possible
                return;
            }

            const message = this.messages.items.find(({ id }) => id == messageId);

            if (!message) {
                //TODO: er is iets fout gegaan??
                return;
            }

            const { error, result } = await payMessage(clientId, message);

            if (error) {
                return error;
            }

            // maybe a new message was sent/received while paying for this message, thus changing the index of the original message
            // Let's go and find the message again
            const index = this.messages.items.findIndex(({ id }) => id == messageId);
            if (index == -1) {
                //impossible
                return;
            }
            //deleting 2 messages here to also removes the system message about the cost
            this.messages.items.splice(index, 2, result);

            //does she still have new messages??
            if ( !this.messages.items.find( m => m.billingStatus == 'NOT PAID' ) ){
                usePerformerStore().noMoreNewMessages( this.performerId );
            }

            useUserStore().account.totalNotifications!--;
        }
        
    },
    getters:{
        filtered():Message[] {
            if (!this.messages){
                return []
            } else {
                return this.messages.items;
            }
        },
        hasNewMessage():boolean{
            if (!this.messages){
                return false;
            }
            if (!this.messages.items.length){
                return false;
            }

            return this.messages.items[0].sentBy == 'PERFORMER' && this.messages.items[0].readStatus == 'NEW';
        }
    }
});
