<script setup lang="ts">
    import { RouterView } from 'vue-router';
    import BottomMenu from './components/BottomMenu.vue';
    import Morpheus from './views/morpheus.vue';
    import { useRoute } from 'vue-router';
    import TopPopin from './components/TopPopin.vue';
    import { onMounted } from 'vue';
    import { useDeviceStore } from '@/stores/device';
    
    const route = useRoute();

    onMounted( ()=> useDeviceStore().registerServiceWorker() );
</script>

<template>
    <RouterView :key="route.name as string" />
    <TopPopin></TopPopin>
    <BottomMenu />
    <Morpheus />
    <svg style="height:0">
        <defs>
            <filter id="filter-shade" x="-100.0%" y="0%" width="306.2%" height="300%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
    </svg>
</template>

<style lang="scss" scoped>
</style>